import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

import { MapContainer } from './map.styled';
const mapState = { center: [59.942624, 30.364153], zoom: 16 };

export default class MapBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height1: 530,
    };
  }

  componentDidMount() {
    this.setState({ height1: window.innerWidth < 992 ? 250 : 530 });
  }

  render() {
    const { height1 } = this.state;
    const { fullWidth, height } = this.props;
    const h = fullWidth ? height1 : height;
    const cl = this.props.fullWidth ? '' : 'block';

    return (
      <MapContainer className={cl}>
        <YMaps>
          <Map
            state={mapState}
            width={-1}
            height={h}
            instanceRef={(ref) => {
              ref && ref.behaviors.disable('scrollZoom');
            }}
          >
            <Placemark
              geometry={{
                type: 'Point',
                coordinates: [59.942624, 30.364153],
              }}
              properties={{
                iconContent: 'ул. Радищева, д. 39, литера Д, офис 312',
              }}
              options={{
                preset: 'islands#blueStretchyIcon',
              }}
            />
          </Map>
        </YMaps>
      </MapContainer>
    );
  }
}

MapBlock.defaultProps = {
  fullWidth: true,
  height: 300,
};
