import styled from 'styled-components';

import { Container } from '../../styledComponents';
import Form from '../../forms/Feedback';

export const StyledContainer = styled(Container)`
  .response-window {
    margin: 20px auto;
    box-shadow: none;
  }
`;

export const Title = styled.h1`
  margin-bottom: 60px;
  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
`;

export const MapContainer = styled.div`
  width: 100vw;
  min-height: 530px;
  position: relative;
  left: calc(-50vw + 50%);
  @media (max-width: 991px) {
    min-height: 250px;
    margin: 0 0 15px;
  }
`;

export const ContactData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
  div {
    display: flex;
    align-items: center;
    color: #2d2d2d;
    font-size: 18px;
    font-family: montserrat-bold;
    img {
      margin-right: 24px;
    }
  }
  a {
    color: #2d2d2d;
  }
`;
export const Separator = styled.div`
  width: 100vw;
  height: 1px;
  background-color: #f2f2f2;
  position: relative;
  left: calc(-50vw + 50%);
  margin-bottom: 20px;
`;
export const StyledForm = styled(Form)`
  box-shadow: none;
  margin: 0 auto;
`;
