import styled from 'styled-components';

export const MapContainer = styled.div`
  width: 100vw;
  min-height: 530px;
  position: relative;
  left: calc(-50vw + 50%);
  @media (max-width: 991px) {
    min-height: 250px;
    margin: 0 0 15px;
  }
  &.block {
    width: 100%;
    min-height: 300px;
    left: auto;
    @media (max-width: 991px) {
      min-height: 250px;
      margin: 0 0 15px;
    }
  }
`;
