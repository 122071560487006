import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 130px;
  @media (max-width: 991px) {
    margin-top: 90px;
  }
`;

export const Link = styled.a`
  position: relative;
  cursor: pointer;
  margin-bottom: 3px;
  font-size: 18px;
  line-height: 1.56;
  color: #fc5b0e;
  padding-right: 20px;
  width: 325px;
  display: inline-block;
  transition: .2s all ease-in;
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: -25px;
    top: 36%;
    width: 34px;
    height: 8px;
    background-image: url(/static/images/orange-arrow.png);
    background-repeat: no-repeat;
  }
  &:hover{
    color: #cc2b0e;
  }

`;

export const Buttons = styled.div`
  margin: 0 0 30px;
`;
