import React, { Component } from 'react';

import Layout from '../components/layouts';
import MapBlock from '../components/map/map';
import { ContactData, Separator, StyledContainer, StyledForm, Title } from '../components/pages/contacts/contacts.styled';

export default class Contacts extends Component {
  componentDidMount() {
    document.body.style.background = '#fff';
  }

  render() {
    return (
      <Layout>
        <StyledContainer>
          <Title>Контакты</Title>
          <MapBlock />
          <ContactData>
            <div>
              <img alt="Адрес" src="/static/images/location.png" />
              Санкт-петербург
              <br />
              ул. Радищева, д. 39, литера Д, офис 312
            </div>
            <div>
              <img alt="Телефон" src="/static/images/phone.png" />
              <a href="tel:+78123093645">7-812-309-36-45</a>
            </div>
            <div>
              <img alt="email" src="/static/images/mail.png" />
              <a href="mailto:office@cursusts.com">office@cursusts.com</a>
            </div>
          </ContactData>
          <Separator />
          <StyledForm className="page-form" />
        </StyledContainer>
      </Layout>
    );
  }
}
